<script setup>
import { useQuasar } from 'quasar';
import { nextTick, ref, watchEffect } from 'vue';

let animationFrameId = null;
let grid = [];
let cols = [];
let rows = [];

const cellColorsLight = [
    '#f5f5f5', '#e8eaf6', '#d1d9f0', '#ced9fd', '#b3c2fa',
    '#9caffb', '#85b2fa', '#6b8cf9', '#5296f7', '#3965f7',
    '#2e53d1', '#2342ab', '#0827f5', '#061dd0', '#0413ab'
];

const cellColorsDark = [
    '#272727', '#232860', '#1f2969', '#1b1b72', '#142466',
    '#0f1f75', '#0c1a84', '#001e88', '#00179b', '#0016a1',
    '#0012b0', '#000cb6', '#000aa0', '#000890', '#0827f5'
];

const quasar = useQuasar();

const wallpaperCanvasElement = ref(null);

function interpolateColor(color1, color2, factor) {
    let result = '#';
    for (let i = 1; i <= 5; i += 2) {
        let hex1 = parseInt(color1.slice(i, i + 2), 16);
        let hex2 = parseInt(color2.slice(i, i + 2), 16);
        let hex = Math.round(hex1 + (hex2 - hex1) * factor).toString(16);
        result += ('00' + hex).substr(hex.length);
    }
    return result;
}

function drawCell(ctx, x, y, cellSize, gap, colors) {
    const cell = grid[y][x];
    let color;
    if (cell.progress < 1) {
        color = interpolateColor(colors[cell.currentColorIndex], colors[cell.targetColorIndex], cell.progress);
        cell.progress += 0.05;
    }
    else {
        color = colors[cell.targetColorIndex];
    }
    ctx.fillStyle = color;
    ctx.fillRect(x * (cellSize + gap), y * (cellSize + gap), cellSize, cellSize);
}

function updateGrid(colors) {
    for (let i = 0; i < 50; i++) {
        const x = Math.floor(Math.random() * cols);
        const y = Math.floor(Math.random() * rows);
        const cell = grid[y][x];
        if (cell.progress >= 1) {
            cell.currentColorIndex = cell.targetColorIndex;
            do {
                cell.targetColorIndex = Math.floor(Math.random() * colors.length);
            }
            while (cell.targetColorIndex === cell.currentColorIndex);
            cell.progress = 0;
        }
    }
}

function drawGrid(ctx, cellSize, gap, colors) {
    for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
            drawCell(ctx, x, y, cellSize, gap, colors);
        }
    }
}

const resizeCanvas = (width = 0, height = 0) => {
    const canvas = wallpaperCanvasElement.value;
    canvas.width = width;
    canvas.height = height;
    canvas.style.position = 'absolute';
    canvas.style.top = '50%';
    canvas.style.left = '50%';
    canvas.style.transform = 'translate(-50%, -50%)';
    initAnimation();
};

function initAnimation() {
    const canvas = wallpaperCanvasElement.value;
    const ctx = canvas.getContext('2d');
    const cellSize = 14;
    const gap = 5;
    const colors = quasar.dark.isActive ? cellColorsDark : cellColorsLight;
    cols = Math.ceil(canvas.width / (cellSize + gap));
    rows = Math.ceil(canvas.height / (cellSize + gap));
    grid = Array.from({ length: rows }, () =>
        Array.from({ length: cols }, () => ({
            currentColorIndex: 0,
            targetColorIndex: 0,
            progress: 1
        }))
    );

    function animate() {
        drawGrid(ctx, cellSize, gap, colors);
        updateGrid(colors);
        animationFrameId = requestAnimationFrame(animate);
    }

    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
    }
    animate();
}

function onOverlayResize(data) {
    nextTick(() => resizeCanvas(data.width, data.height));
}

watchEffect(() => {
    if (wallpaperCanvasElement.value) {
        initAnimation();
    }
});
</script>

<template>
    <div class="contribution-animation-wallpaper">
        <div id="overlay">
            <q-resize-observer @resize="onOverlayResize"/>
        </div>
        <canvas ref="wallpaperCanvasElement" class="canvas-background"></canvas>
    </div>
</template>

<style scoped lang="scss">
.canvas-background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
}

#overlay {
    background: rgba(255, 255, 255, 0.93);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .body--dark & {
        background: rgba(0, 0, 0, 0.5);
    }
}
</style>
